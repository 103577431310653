import { client } from '../client';
import Endpoints from '../Endpoints';
import { getData } from '../apiUtils';

export const projectSetup = (
  domainId: number | undefined
) => {
  return client
    .get(`${Endpoints.projectSetup}/${domainId}`)
    .then(getData);
};

import { useEffect, useMemo, useRef, useState } from 'react';
import { Resizable } from 're-resizable';
import { Box, Button, useTheme, SxProps, Grid } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ReplayIcon from '@mui/icons-material/Replay';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import styled from 'styled-components';
import {
  GeneralIde,
  FrontendOptions,
  FeNames,
  language,
  TourSteps,
  SessionStorageKeys,
  constants,
  frameworks,
  datamodelsUrl,
  mongoDBDatamodelsUrl,
} from '../static/constants';
import moment from 'moment';
import Modal from 'react-modal';
import MonacoEditor from '@monaco-editor/react';
import { runCode } from '../api/requests/runCode';
import { projectSetup } from '../api/requests/projectSetup';
import Tour from 'reactour';
import Tooltip from '@material-ui/core/Tooltip';
import SandpackComponent from './sandpackComponent';
import '../App.css'

const zecodeLogo = '/images/zecodeLogo.svg';
const playButton = '/images/playButton.svg';
const tourButton = '/images/tour.svg';
const Loading = '/images/Load Icon - F.gif';

interface ideDetails {
  language?: string;
  ide?: number;
  code?: string;
  files?: any;
}

interface optionTypes {
  language: string;
  svg: string;
  IDE: number;
  displayName: string;
}

const StyledHeader = styled(Box)`
  display: flex;
  height: 50px;
  top: 0;
  align-items: center;
  background-color: #000821;
  justify-content: space-between;
  padding: 10px;
`;

const DemoAndTourButtonWrapper = styled(Box)`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledLink = styled.a<{ $isDark?: boolean }>`
color:#ffffff;
font-size: 10px;
font-family: 'Inter';
border: 1px solid white;
padding: 6px 6px;
border-radius: 4px;
text-decoration: none;
&:hover {
  color: #4A90E2;
}
`;

const TotalSteps = styled(Box)`
  display: flex;
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1000000;
  color: red;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #ffffff;
  > span {
    color: #4A90E2;
    cursor: pointer;
    font-family: 'Inter';
  }
`;

const NextButton = styled.button<{ currentStep: number }>`
  background: #4A90E2;
  border: 1px solid #4A90E2;
  width: 70px;
  padding: 3px;
  border-radius: 4px;
  font-family: 'Inter';
  font-size: 12px;
  color: #ffffff !important;
  position: relative;
  left: ${(props) =>
    props.currentStep <= 4
      ? props.currentStep === 1
        ? '-50px'
        : '-25px'
      : '0px'};
  &:hover {
    background: #4A90E2;
    cursor: pointer;
  }
`;

const PreviousButton = styled.button<{ currentStep: number }>`
  display: ${(props) => (props.currentStep === 1 ? 'none' : 'inline')};
  background: rgba(0, 0, 0, 0.7);
  border: 1px solid #4A90E2;
  width: 70px;
  padding: 3px;
  font-family: 'Inter';
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff !important;
  position: relative;
  right: ${(props) => props.currentStep > 4 && '-25px'};
  &:hover {
    background: transparent;
    cursor: pointer;
  }
`;

const HighlightedElementWrapper = styled(Box) <{ isCurrentStep?: boolean }>`
  margin: ${(props) => props.isCurrentStep && '4px'};
  border: ${(props) => props.isCurrentStep && '1.5px dashed #4A90E2'};
  padding: ${(props) => props.isCurrentStep && '4px'};
  align-items: center;
`;

const ZesstaLogo = styled.img`
  width: 124px;
  height: 29.53px;
  @media(max-width:430px){
    width: 90px;
  }
  @media(max-width:330px){
    width: 70px;
  }
`;

const ImageWrapper = styled.img`
  width: 12px;
  height: 12px;
  color: red;
`;

const ThemeIcon = styled.img`
  width:  10px;
  height: 10px;
`;

const LanguageOptionsContainer = styled(Box) <{
  $isDark: boolean;
  isCurrentStep?: boolean;
}>`
  display: flex;
  flex-direction: column;
  color: #666666;
  gap: 12px;
  padding: ${(props) => (props.isCurrentStep ? '10px': '')};
  border: ${(props) => (props.isCurrentStep ? '1.5px dashed #4A90E2' : 'none')};
  margin: ${(props) => (props.isCurrentStep ? '0px 0px 10px 5px' : '0px')}; 
  background:#000821;
`;

const IDEText = styled(Box)`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #f1f1f1;
  display: flex;
`;

const IdeName = styled(Box)`
  display: flex;
  background: #15182b;
  border-radius: 4px;
  padding: 10px;
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  color: #f1f1f1;
  margin: 10px 0px;
`;

const EachItem = styled(Box) <{ $selected: boolean, isMobileView:boolean }>`
 white-space:nowrap;
 background-image: ${(props) => (props.$selected ? `linear-gradient(to right, #5F5E5E,#252525)` : '')}; 
 border-radius: 2px;
 border: 1px solid transparent;
 cursor: pointer;
 padding-right: ${(props)=>(props.isMobileView ? '5px': '')};
 &:hover {
  background-image: linear-gradient(to right, #5F5E5E,#252525);
  border-image: linear-gradient(to right, #B9B8C1,#2F2A45) 1;
  border: 1px solid #B9B8C1;
 }
`;

const Container = styled(Box)`
  display:grid;
  grid-template-columns: repeat(2, 1fr);
  gap:15px;
  padding:2px;
  @media(max-width:375px){
    grid-template-columns: repeat(3, 1fr);
  }
`;

const StyledImg = styled.img`
  width: 10px;
  height: 10px;
  padding-right: 5px;
  padding-left: 5px;
`;

const StyledText = styled.span<{ $selected: boolean }>`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 400;
  color: #f1f1f1;
`;

const CodingContainer = styled.div<{
  $isDark?: boolean;
  isCurrentStep?: boolean;
  isWidth?: any;
  isHeight?: any;
}>`
  display: flex;
  width: ${(props) => (props.isWidth ? `calc(138% - ${props.isWidth}px)` : '')};
  min-width: 300px;
  min-height: 100px;
  border: ${(props) => (props.isCurrentStep ? '1.5px dashed #4A90E2' : 'none')};
  padding: 1vw;
  height: 85vh;
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#1e1b31')};
  && .monaco-editor {
    background-color: #000412;
  }
  && .monaco-editor .margin {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
    margin-top: 10px;
  }
  && .monaco-editor-background {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
    margin-top: 10px;
  }
  && canvas.minimap-decorations-layer {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
  }
  && canvas.decorationsOverviewRuler {
    background-color: ${(props) => (props.$isDark ? '#FFFFFF' : '#000412')};
    width: 3px !important;
  }
  && .monaco-scrollable-element > .scrollbar > .slider {
    background-color: #4A90E2;
    width: 3px !important;
  }
  && .monaco-editor .vertical {
    background-color: #000412;
    width: 3px !important;
  }
  && .monaco-editor .margin-view-overlays .line-numbers {
    font-variant-numeric: tabular-nums;
    text-align: center;
    cursor: default;
    height: 100%;
  }
  @media(max-width:1024px){
    width: ${(props) => (props.isWidth ? `calc(145% - ${props.isWidth}px)` : '')};
  }
  @media(max-width:768px) {
    gap: 10px;
    width: 98%;
    height: ${(props) => (props.isHeight ? `calc(75vh - ${props.isHeight}px)` : '85vh')};
  }
`;

const CodingButtonsContainer = styled.div<{ $isDark?: boolean }>`
  width:100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ResetRunButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  gap:20px;
  @media(max-width: 320px){
    gap:4px;
  }
`;

const ResetButton = styled.button<{isResetButton?:boolean}>`
  background: ${(props) => props.isResetButton ? 'transparent':'#11ac85'};
  border-radius: 4px;
  border:${(props) => props.isResetButton ? '1px solid white':'1px solid #11ac85'} ;
  display: flex;
  gap:4px;
  align-items: center;
  color: white;
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter';
  height: 26px;
  cursor: pointer;
`;

const PlayTourButton = styled.img`
  width: 20px;
  height: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const CodeText = styled.p`
  font-family: 'Inter';
  font-size: 12px;
  font-weight: 500;
  color: #f1f1f1;
`;

const StyledSideContainer = styled.div<{
  $isDark?: boolean;
  isCurrentStep?: boolean;
  isHeight?: any;
  isWidth?:any;
}>`
  display: flex;
  border: ${(props) => props.isCurrentStep && '1.5px dashed #4A90E2'};
  flex-direction: column;
  width: width;
  height: 85vh;
  gap: 20px;
  padding: 15px;
  border: ${(props) => props.isCurrentStep && '1.5px dashed #4A90E2'};
  overflow: auto;
  background-color: ${(props) => (props.$isDark ? '#1a1919' : '#1E1B31')};
  color: #ffffff;
  @media(max-width:768px) {
    height: ${(props) => (props.isHeight > 150 ? `${props.isHeight}px` : '28vh')};
    border-radius:${(props) => (props.isHeight > 150 ? `10px` : '')};
    transition: auto 0.25s ease-in-out 0s;
      }
`;

const JoinButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  color: #999999;
  font-family: 'Inter';
  flex-direction: row;
  padding-top: 10px;
  align-items: center;
`;

const Output = styled(Box)`
  display: flex;
  font-size: 12px;
`;

const ClearLog = styled.span<{ $isDark?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.$isDark ? '#1e1e1e' : '#f1f1f1')};
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  text-decoration-line: underline;
  &:hover {
    color: ${(props) => (props.$isDark ? '#1e1e1e' : '#4A90E2')};
  };
`;

const OutputWrapper = styled(Box) <{ $isDark?: boolean }>`
  padding: 15px;
  background: #15182b;
  overflow-x: auto;
  white-space: break-spaces;
  color: ${(props) => (props.$isDark ? '#1e1e1e' : '#FFFFFF')};
  font-size: 12px;
  height: 100%;
  overflow: auto;
  ::-webkit-scrollbar-thumb {
    background-color: #4A90E2;
    border-radius: 50px;
  }
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
  }
  ::-webkit-scrollbar-track {
    margin: 10px;
    background-color: transparent;
    border-radius: 50px;
  } && table thead tr th,
  table tbody tr td {
    color: ${(props) => (props.$isDark ? '#1e1e1e' : '#FFFFFF')};
    border: ${(props) =>
    props.$isDark ? '1px dashed #1e1e1e' : '1px dashed #FFFFFF'};
    padding: 5px;
    font-family: 'Inter';
  }
  && table {
    border-collapse: collapse;
  }
`;

const Iframe = styled.iframe`
  border: 0px;
`;

const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: 'Inter';
`;

const MainText = styled(Box)`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
  white-space: pre;
`;

const SubText = styled(Box)`
  display: flex;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  color: #ffffff;
  white-space: pre;
`;

const CustomTour = styled(Tour) <{ currentStep: number }>`
  display: flex;
  flex-direction: column;
  justify-content: start;
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
  span{
  display:none;
  font-size:32px;
  }
  div {
  display:flex;
  justify-content: ${(props) => (props.currentStep <= 4 ? 'start' : 'end')};
  font-family:'Inter';
   }
  button {
    color:#ffffff;
    &:hover {
      color:#4A90E2;
    }
    span{
      display:block;
    }
  }
`;

const CurrentStep = styled(Box) <{
  leftSpace?: string;
  rightSpace?: string;
  topSpace?: string;
}>`
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  font-weight: 400;
  font-size: 16px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background: #4A90E2;
  position: relative;
  top: ${(props) => props.topSpace && props.topSpace};
  left: ${(props) => props.leftSpace && props.leftSpace};
  right: ${(props) => props.rightSpace && props.rightSpace};
`;

const StyledArrow = styled.img<{
  leftSpace?: string;
  rightSpace?: string;
  topSpace?: string;
}>`
  height: 96px;
  position: relative;
  z-index: 1000;
  top: ${(props) => props.topSpace && props.topSpace};
  left: ${(props) => props.leftSpace && props.leftSpace};
  right: ${(props) => props.rightSpace && props.rightSpace};
`;

let className = '';

const Coding = () => {
  const dropdownRef = useRef<any>(null);
  const mainTheme = useTheme();
  const [codeFiles, setCodeFiles] = useState<any>();  
  const [ideDetails, setIdeDetails] = useState<ideDetails>({
    language: 'php',
    ide: 5,
    code: `<?php
    $txt = "Hello world!";
    echo $txt;
    ?>`,
    files: codeFiles,
  });
  const themes: boolean = false;
  const status: string = 'true';
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [output, setOutput] = useState<any>();  
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [activeFile, setActiveFile] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showERDiagram, setShowERDiagram] = useState<boolean>(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedIde, setSelectedIde] = useState(null);
  const [open, setOpen] = useState(false);
  const [width, setWidth] = useState(350);
  const [height, setHeight] = useState(150);
  const containerRef = useRef<HTMLDivElement>(null);
  const [maxWidth, setMaxWidth] = useState(0);  
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (containerRef.current) {
      const totalWidth = containerRef.current.offsetWidth;      
      setMaxWidth(totalWidth - (totalWidth/2));
      const totalHeight = containerRef.current.offsetHeight;      
      setMaxHeight(totalHeight - (totalHeight)/4)
    }
  }, [containerRef.current]);

  const toggleOptionsIcon = () => {    
    setOpen((prevOpen) => !prevOpen);
    setShowOptions(!showOptions);

  };

  const handleClickOutside = (event:any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
      setShowOptions(false);
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [open]);

  const handleIdeClick = (ide: any) => {
    setSelectedIde(ide);
    setShowOptions(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); 

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const clickModal = () => {
    setShowERDiagram(true);
  };

  const closeModal = () => {
    setShowERDiagram(false);
  };
  
  const JsOrTs =
    ideDetails?.language === language.Typescript ||
    ideDetails?.language === language.Javascript;

  //Jupyter Notebook URL
  const jupyterURL = process.env.REACT_APP_JUPYTER_NOTEBOOK_URL;

  const nextButton = <NextButton currentStep={currentStep}>Next</NextButton>;
  const previousButton = (
    <PreviousButton currentStep={currentStep}>Previous</PreviousButton>
  );
  const finishButton = (
    <NextButton currentStep={currentStep}>Finish</NextButton>
  );
  const steps: any = [
    {
      selector: '#ide',
      content: ({ goTo, inDOM }: any) => (
        <>
          <StyledArrow
            src={TourSteps.step1.arrow}
            leftSpace='-200px'
            rightSpace='0px'
            topSpace='35px'
            alt='arrow'
          />
          <CurrentStep leftSpace='-50px' rightSpace='-25px' topSpace='35px'>
            {currentStep}
          </CurrentStep>
          <TextWrapper>
            <MainText>{TourSteps.step1.mainText}</MainText>
            <SubText>{TourSteps.step1.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: 'transparent',
        marginLeft: '5vh',
        marginTop: '10vh',
        boxShadow: 'none !important',
        color: '#FFFFFF',
        borderColor: 'transparent !important',
        '@media(max-width:768px)':{
          marginTop:'0vh',
          background:'red'
        }
      },
      position: 'top',
    },
    {
      selector: '#coding',
      content: ({ goTo, inDOM }: any) => (
        <>
          <Box>
            <CurrentStep leftSpace='-50px' rightSpace='-25px' topSpace='90px'>
              {currentStep}
            </CurrentStep>
            <StyledArrow
              src={TourSteps.step2.arrow}
              leftSpace='-100px'
              rightSpace='0px'
              alt='arrow'
            />
          </Box>
          <TextWrapper>
            <MainText>{TourSteps.step2.mainText}</MainText>
            <SubText>{TourSteps.step2.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: 'transparent',
        color: '#FFFFFF',
        border: 'none !important',
        minWidth: '25vw',
      },
      position: 'center',
    },
    {
      selector: '#reset',
      content: ({ goTo, inDOM }: any) => (
        <>
          <StyledArrow
            src={TourSteps.step3.arrow}
            rightSpace='195px'
            topSpace='42px'
            alt='arrow'
          />
          <CurrentStep leftSpace='-50px' topSpace='40px'>
            {currentStep}
          </CurrentStep>
          <TextWrapper>
            <MainText>{TourSteps.step3.mainText}</MainText>
            <SubText>{TourSteps.step3.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: 'transparent',
        marginTop: '-80px',
        marginLeft: '-10px',
        color: '#FFFFFF',
        border: 'none !important',
      },
      position: 'bottom',
    },
    {
      selector: '#run',
      content: ({ goTo, inDOM }: any) => (
        <>
          <StyledArrow
            rightSpace='165px'
            topSpace='42px'
            src={TourSteps.step4.arrow}
            alt='arrow'
          />
          <CurrentStep leftSpace='-50px' topSpace='40px'>
            {currentStep}
          </CurrentStep>
          <TextWrapper>
            <MainText>{TourSteps.step4.mainText}</MainText>
            <SubText>{TourSteps.step4.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: 'transparent',
        marginLeft: '50px',
        marginTop: '-70px',
        color: '#FFFFFF',
        border: 'none !important',
      },
      position: 'bottom',
    },
    {
      selector: '#output',
      content: ({ goTo, inDOM }: any) => (
        <>
          <StyledArrow
            src={TourSteps.step5.arrow}
            leftSpace='215px'
            topSpace='42px'
            alt='arrow'
          />
          <CurrentStep leftSpace='325px' topSpace='40px'>
            {currentStep}
          </CurrentStep>
          <TextWrapper>
            <MainText>{TourSteps.step5.mainText}</MainText>
            <SubText>{TourSteps.step5.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: '#000000',
        marginLeft: '3%',
        color: '#FFFFFF',
        border: 'none !important',
      },
      position: 'center',
    },
    {
      selector: '#demo',
      content: ({ goTo, inDOM }: any) => (
        <>
          <StyledArrow
            src={TourSteps.step6.arrow}
            leftSpace='200px'
            topSpace='45px'
            alt='arrow'
          />
          <CurrentStep leftSpace='350px' rightSpace='-25px' topSpace='42px'>
            {currentStep}
          </CurrentStep>
          <TextWrapper>
            <MainText>{TourSteps.step6.mainText}</MainText>
            <SubText>{TourSteps.step6.subText}</SubText>
          </TextWrapper>
        </>
      ),
      style: {
        backgroundColor: 'transparent',
        marginTop: '-50px',
        marginLeft: '-70px',
        color: '#FFFFFF',
        border: 'none !important',
      },
      position: 'top',
    },
  ];

  const template: string | undefined = FrontendOptions?.filter((id:any) => id?.IDE === ideDetails?.ide)[0]?.value  

  const intitalFileState = useMemo(() => {
    const result: { [key: string]: string } = {};
    if (ideDetails?.files) {
      Object.entries(ideDetails.files)?.forEach(([key, value]) => {
        result[key] = (value as { code: string })?.code
      })
    }

    return result;
  }, [ ideDetails]);

  const handleUpdation = (data: any) => {
    setCodeFiles(data);
    setActiveFile(activeFile);
    const existingQuestions = ideDetails;    
    if (existingQuestions) {
      existingQuestions.files = data;
      setIdeDetails(existingQuestions);
    }

  }

  const fetchProjectSetupData = async () => {
    const domainId = ideDetails.ide;
    const language = `${ideDetails.language}`;
    const projectSetupDetails = await projectSetup(domainId);
    setCodeFiles(projectSetupDetails?.data?.setup?.files);
    setIdeDetails({...ideDetails, files:projectSetupDetails?.data?.setup?.files})
    if (FeNames.includes(language)) {
      if (JsOrTs) {
      } else {
        setIdeDetails({
          ...ideDetails,
          code: projectSetupDetails?.data?.setup?.code,
        });
      }
    } else {
      setIdeDetails({
        ...ideDetails,
        code: projectSetupDetails?.data?.setup?.code,
      });
    }
  };

  const handleEditorChange = (value: string | undefined) => {
    setIdeDetails({
      ...ideDetails,
      code: value,
    });
  };

  const codeExecute = async () => {
    setLoading(true);
    const sourceCode = ideDetails?.code;
    const template = ideDetails?.language;
    const response = await runCode(template, sourceCode);
    if (response?.exitCode || response.code) {
      if (response.code) {
        if(template === 'sql') className='error'
        const sqlErrorMessage = `ERROR: ${response.errno} (${response.sqlState}) ${response.sqlMessage}`;
        setOutput(sqlErrorMessage);
      } else {
        setOutput(response?.stderr);
      }
    } else if (response?.status === 'error') {
      setOutput(response?.message);
    } else if (response?.exitCode === 0) {
      setOutput(response?.stdout);
    } else if (response?.length === 0) {
      setOutput("No Records Found");
    } else if (response?.info === '') {
      setOutput('');
    } else {
      setOutput(response); 
      className=''
    }
    setLoading(false);
    setHeight(500);    
  };

  const resetCode = async () => {
    const response = await projectSetup(ideDetails?.ide);    
    setIdeDetails({
      ...ideDetails,
      code: response?.data?.setup?.code,
    });
    setOutput('');
  };

  const requestDemo = () => {
    window.open('https://zecode.live/contact-us/', '_self');
  };

  const onIdeChange = (IDE: number, language: string) => {
    const div = document.getElementById('log') as HTMLElement;
    if (div?.innerHTML) {
      div.innerHTML = '';
    }
    setIdeDetails({
      ide: IDE,
      language: language,
    });
    setOutput('');
    setOpen(false)
  };

  const onTourCloseHandler = () => {
    setIsTourOpen(false);
    setCurrentStep(1);
    sessionStorage.setItem(
      SessionStorageKeys.tourStatus,
      JSON.stringify(false)
    );
  };

  const playTourButtonHandler = () => {
    setIsTourOpen(true);
    sessionStorage.setItem(SessionStorageKeys.tourStatus, JSON.stringify(true));
  };

  const clearLog = () => {
    setOutput('');
  }

  const onCloseHandler = () => {
    setHeight(150);    
  }

  const selectedLanguage = (key?: number) =>{
    if(key! <= 4 || key === 17){
      const frontEndLanguage = FrontendOptions?.find((obj:any) => obj.IDE === key)?.displayName;
      return frontEndLanguage;
    }
    else if (key === 13){
      const sql = frameworks?.find((obj:any) => obj.IDE === key)?.displayName;
      return sql;
    }
    else {
      const backendLanguage =  GeneralIde?.find((obj:any) => obj.IDE === key)?.displayName;
      return backendLanguage;}
  }  

  useEffect(() => {
    fetchProjectSetupData();
  }, [ideDetails?.ide]);

  //To do for later - Commenting to skip default code arena tour 
  
  // useEffect(() => {
  //   const tourStatus = sessionStorage.getItem(SessionStorageKeys.tourStatus);
  //   if (tourStatus === null) {
  //     setIsTourOpen(true);
  //     sessionStorage.setItem(
  //       SessionStorageKeys.tourStatus,
  //       JSON.stringify(true)
  //     );
  //   } else {
  //     setIsTourOpen(JSON.parse(tourStatus));
  //   }
  // }, []);
  
  const IdeOptionsComponent = (
    title: string,
    options: optionTypes[],
    splitCount: number
  ) => {
    return (
      <Box>
        <IdeName>{title}</IdeName>
         <Container theme={mainTheme}>        
            {options.map(({ language, svg, IDE, displayName }) => {
              return (
                <>
                  <EachItem
                    $selected={ideDetails.ide === IDE}
                    isMobileView={isMobileView}
                    onClick={() => onIdeChange(IDE,language)}
                    id={language}
                  >
                    <StyledImg src={svg} alt={language} />
                    <StyledText $selected={ideDetails.ide === IDE}>
                      {displayName}
                    </StyledText>
                  </EachItem>
                </>
              );
            })
 }
        </Container>
      </Box>
    );
  };

  const OutputConsoleDiv = (
    <StyledSideContainer
    isHeight={height}
    $isDark={themes}
    isWidth={width}
    id='output'
    isCurrentStep={currentStep === 5 && isTourOpen}
  >
   <Box sx={{ marginTop:'-26px',display:'flex', flexDirection:'column'}}>{isMobileView &&  <Button disableRipple onClick={onCloseHandler} sx={{...closeModalButton, background:'transparent', minWidth:0}}><DragHandleIcon sx={{color:'#4a4a4a'}}/></Button>}
  </Box> 
       <JoinButtonsContainer>
<Output>Output Console</Output>
<ClearLog onClick={clearLog} $isDark={themes}>
  Clear Log
</ClearLog>
</JoinButtonsContainer>
    <OutputWrapper $isDark={themes}>
      {ideDetails?.language === language.sql &&
        output && output[0] !== 'E' && output[0] !== 'N' ? (
        <table>
          <thead>
            <tr>
              {output
                ? Object.keys(output[0])?.map((obj, index) => (
                  <th key={index}>{obj}</th>
                ))
                : ''}
            </tr>
          </thead>
          <tbody>
            {output?.length > 0 &&
              output?.map((obj: any, rum: any) => (
                <tr key={rum}>
                  {Object.entries(obj)?.map(
                    (item: any, ind) => (
                      <td key={ind}>
                        {item[0].includes('DATE') ||
                          item[0].includes('FROM')
                          ? moment(item[1]).format('DD-MM-YYYY')
                          : item[1]}
                      </td>
                    )
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <div>
        {output?.split('\n').map((line:any, index:any) => {
          if (line.includes('warning')) {
            className = 'warning';
          } else if (line.includes('error')) {
            className = 'error';
          }
  
          return (
            <div key={index} className={className} style={{fontFamily:'Inter', fontSize:'12px'}}>
              {line}
            </div>
          );
        })}
      </div>
      )}
    </OutputWrapper>
</StyledSideContainer>
  )

  return (
    <Box ref={containerRef}>
      <CustomTour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={onTourCloseHandler}
        showNavigation={false}
        accentColor='#4A90E2'
        getCurrentStep={(currentStep) => setCurrentStep(currentStep + 1)}
        nextButton={nextButton}
        prevButton={previousButton}
        lastStepNextButton={finishButton}
        showCloseButton={false}
        currentStep={currentStep}
        maskSpace={5}
        startAt={0}
      />
      {isTourOpen && currentStep !== 6 && (
        <TotalSteps>
          Total 6 Steps &nbsp; &nbsp;|{' '}
          <span onClick={onTourCloseHandler}>
            &nbsp; &nbsp;Skip &nbsp;&#62;
          </span>{' '}
        </TotalSteps>
      )}
      <StyledHeader>
        <Box style={{display:'flex', flexDirection:'row'}}>
        <ZesstaLogo
          id='picture'
          className='Logo'
          src={zecodeLogo}
          alt='zessta'
        />
        <Box sx={codeArenaLabel}>
      <b >||</b>
      <b style={{ color: '#9163FF' }}>Code </b>
      <b >Arena</b>
    </Box>
        </Box>
        <DemoAndTourButtonWrapper>
          {ideDetails?.ide && (ideDetails?.ide <=4 || ideDetails?.ide === 17) || isMobileView ? ('') :
          (  <Tooltip title='How to use'>
              <PlayTourButton
                src={tourButton}
                alt='tourButton'
                onClick={playTourButtonHandler}
              />
            </Tooltip>) }
          <HighlightedElementWrapper
            id='demo'
            isCurrentStep={currentStep === 6 && isTourOpen}
          >
            <Button onClick={requestDemo} sx={requestDemoButton}>Request Demo</Button>
          </HighlightedElementWrapper>
        </DemoAndTourButtonWrapper>
      </StyledHeader>
      <Grid container spacing={1} sx={{overflowY:'auto', maxHeight:'100%', background:'#000821', padding:'10px',  '@media (max-width:769px)': {
            maxHeight: 'calc(100vh - 50px)',
            height:'100vh',
          },}}>
        <Grid item sm={12} md={3} lg={2} >
        <LanguageOptionsContainer
          $isDark={themes}
          id='ide'
          isCurrentStep={currentStep === 1 && isTourOpen}
        >
          <IDEText>Select IDE</IDEText>
          <div ref={dropdownRef}>
      {isMobileView ? (
        <div>
              <Button onClick={toggleOptionsIcon} sx={selectLanguageButton} endIcon={
      open ? <ExpandLessIcon /> : <ExpandMoreIcon />
    }>
      {selectedLanguage(ideDetails?.ide)}
    </Button>
          {showOptions && (
            <Box sx={languageWrapperStyles}>
              <div onClick={() => handleIdeClick('General')}>
                {IdeOptionsComponent('General', GeneralIde, 3)}
              </div>
              <div onClick={() => handleIdeClick('Front End')}>
                {IdeOptionsComponent('Front End', FrontendOptions, 5)}
              </div>
              <div onClick={() => handleIdeClick('Database')}>
                {IdeOptionsComponent('Database', frameworks, 5)}
              </div>
            </Box>
          )}
        </div>
        
      ) : (
        <>
          {IdeOptionsComponent('General', GeneralIde, 7)}
          {IdeOptionsComponent('Front End', FrontendOptions, 5)}
          {IdeOptionsComponent('Database', frameworks, 5)}
        </>
      )}
    </div>
        </LanguageOptionsContainer>
        </Grid>
        <Grid item sm={12} md={ideDetails?.ide && (ideDetails?.ide <=4 || ideDetails?.ide === 17) ? 9 : 6} lg={ideDetails?.ide && (ideDetails?.ide <=4 || ideDetails?.ide === 17) ? 10 : 7} sx={{
          '@media (max-width:769px)': {
            width: '100%',
          },
          paddingLeft: '8px', 
        }}>
        <Box sx={ideDetails?.ide && (ideDetails?.ide <=4 || ideDetails?.ide === 17) ? sandpackStyles : styledMainContainer }>
          {ideDetails?.language === language.jupyter ?
            <Iframe
              title='Interactive Jupyter Notebook'
              src={jupyterURL}
              height='100%'
            />
            :
            <>
                {ideDetails?.ide && (ideDetails?.ide <=4 || ideDetails?.ide === 17) ? (
                  <>
                      <Box sx={sandpackComponentStyles} >
                        <SandpackComponent
                          template={template}
                          activeFile={activeFile}
                          intitalFileState={intitalFileState}
                          codeFiles={codeFiles}
                          handleUpdation={handleUpdation}
                          setActiveFile={setActiveFile}
                        />
                      </Box>
                  </>
                ) : (
                    <CodingContainer
                      isHeight={height}
                      isWidth={width}
                      $isDark={themes}
                      id='coding'
                      isCurrentStep={currentStep === 2 && isTourOpen}
                    >
                      <CodingButtonsContainer $isDark={themes}>
                  {ideDetails?.ide === 13 || ideDetails?.ide === 25 ? '' : <CodeText>{constants.code}</CodeText>}
                  {(ideDetails?.ide === 13 || ideDetails?.ide === 25) && <>
                    <StyledLink href='#' onClick={clickModal}>
                      Open ER Diagram
                    </StyledLink>
                    <Modal
                      isOpen={showERDiagram}
                      onRequestClose={closeModal}
                      contentLabel='ER Diagram'
                      className='datamodelscontainer'
                    >
                      <img
                        className='datamodels'
                        src={ideDetails?.ide === 13 ? datamodelsUrl[0].url : mongoDBDatamodelsUrl[0].url}
                        alt='datamodel'
                      />
                      <Button
                        sx={closeModalButton}
                        onClick={closeModal}
                      >
                        Close
                      </Button>
                    </Modal></>}
                  <ResetRunButtonsContainer>
                  <HighlightedElementWrapper
                      id='run'
                      isCurrentStep={currentStep === 4 && isTourOpen}
                    >
                      <ResetButton
                        onClick = {codeExecute}
                      >
                         {loading ? (
            <ImageWrapper src={Loading} />
          ) : (
            <ThemeIcon src={playButton} alt='playButton' />
          )}
                        <Box>Run Code</Box>
                      </ResetButton>
                    </HighlightedElementWrapper>
                    {status ? (
                      <HighlightedElementWrapper
                        id='reset'
                        isCurrentStep={currentStep === 3 && isTourOpen}
                      >
                        <ResetButton onClick={resetCode} isResetButton>
                          <Box>Reset code</Box>
                          <ReplayIcon sx={{width:'14px'}}/>
                        </ResetButton>
                      </HighlightedElementWrapper>
                    ) : (
                      ''
                    )}
                  </ResetRunButtonsContainer>
                </CodingButtonsContainer>
                      <MonacoEditor
                        language={ideDetails?.language === "python2" || ideDetails?.language === "python3"
                          ? "python"
                          : ideDetails?.language}
                        value={ideDetails?.code}
                        onChange={handleEditorChange}
                        theme={themes ? 'light' : 'vs-dark'}
                        path={ideDetails?.language}
                        options={{ minimap: { enabled: false } }}
                      />
                    </CodingContainer>
                )}
            </>
          }
        </Box>
        </Grid>
           {ideDetails?.ide && ideDetails?.ide > 4 && ideDetails?.ide !== 17? 
        <Grid item sm={12} md={3} lg={3}  sx={{
          position:'relative',
          '@media (max-width:769px)': {
            width: '100%',
          },
          paddingLeft: '8px',
        }}> 
        {!isMobileView ? 
        <Resizable enable={{left:true}} size={{ width, height: '100%' }}  onResizeStop={(e, direction, ref, d) => {
              setWidth(width + d.width);
            }}
            style={{ position: 'absolute', right: 0 }}
            maxWidth={maxWidth} minWidth={350}>       
        {OutputConsoleDiv}
        </Resizable> :
        <Resizable enable={{top:true}} size={{ width:'100%', height }}  onResizeStop={(e, direction, ref, d) => {          
          setHeight(height + d.height);
        }}
        style={{ bottom: 0 }}
        maxHeight={maxHeight} minHeight={150}> {OutputConsoleDiv}</Resizable>}
        </Grid>:''}
      </Grid>
      </Box>
  );
};

export default Coding;

const languageWrapperStyles:SxProps  = 
{ 
  display: 'flex', 
  flexDirection: 'row', 
  justifyContent: 'space-between', 
  background:'#211c1f', 
  border: '1px solid #4A90E2',
  marginTop: '4px',
  borderRadius:'4px',
  '@media(min-width:520px) and (max-width: 768px)':{width:'75vw'},
  '@media(max-width:425px)':{
    flexWrap:'wrap',
  }
 };

 const sandpackComponentStyles = {
  height: '100%',
  marginLeft:'10px',
  '@media (max-width:768px)': {
    width:'96vw',
    marginLeft:'0px'
  },
}

const selectLanguageButton:SxProps = {
  textTransform: 'capitalize',
  background:'#4A90E2',
  fontSize:'12px',
  color: 'white'
};

const requestDemoButton:SxProps = {
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontWeight: 400,
  color: '#f1f1f1',
  textTransform: 'capitalize',
  fontSize: '12px !important',
  backgroundColor: 'transparent',
  border: '0.75px solid #4A90E2',
  borderRadius: '4px',
  padding:'3px 10px',
  '&:hover': {
    background: '#4A90E2',
  }
}

const styledMainContainer:SxProps = {
  '@media(max-width:768px)': {
 }
}

const sandpackStyles:SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  gap: '5px',
}

const codeArenaLabel:SxProps = {
  color:'white', 
  fontSize:'16px', 
  fontFamily:'Inter', 
  display:'flex', 
  alignItems:'center',
  marginLeft:'10px', 
  gap:'5px',
  '@media(max-width:380px)':{
    fontSize:'12px', 

  }
}

const closeModalButton:SxProps = {
  background:'#4A90E2', 
  color:'white', 
  padding:'3px 8px', 
  borderRadius:'4px',
  fontFamily:'Inter',
  fontSize:'12px',
  textTransform:'capitalize'
}

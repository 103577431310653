import { client } from '../client';
import Endpoints from '../Endpoints';
import { getData } from '../apiUtils';

export const runCode = (
  language?: string,
  sourcecode?: string,
  id?: string
) => {
  return client
    .post(`${Endpoints.runCodeUrl}`, {
      language,
      sourcecode,
      id,
    })
    .then(getData);
};

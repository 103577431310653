import React from 'react';
import {
    SandpackProvider,
    SandpackPreview,
    SandpackCodeEditor,
    SandpackStack,
    SandpackLayout,
    // SandpackFileExplorer,
    useSandpack,
} from '@codesandbox/sandpack-react';
import { SandpackFileExplorer } from 'sandpack-file-explorer';
import styled from 'styled-components';
import { nightOwl } from '@codesandbox/sandpack-themes';
import { SxProps, Box } from '@mui/material';

const SandpackCodeEditorWrapper = styled(SandpackCodeEditor)`
   .sp-c-gGbYbQ::-webkit-scrollbar-thumb {
     background-color: #4a90e2;
      border-radius: 50px;
    }
    .sp-c-gGbYbQ::-webkit-scrollbar {
      width: 2px;
      height: 5px;
    }
    .sp-c-gGbYbQ::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 50px;
      margin-left: 10px;
    }
    @media(max-width:768px){
    height: 400px !important;
    }
`

const SandpackFileExplorerWrapper = styled(SandpackFileExplorer)`
  & div:nth-child(2){
    padding-left: 18px;
  }
  @media(max-width:768px){
    height: 300px !important;
    }
`;

const SandpackPreviewWrapper = styled(SandpackPreview)`

  @media(max-width:768px){
    height: 300px !important;
    }
`;

const CodeViewer = (props: any) => {
    const { sandpack } = useSandpack();
    const { files, activeFile } = sandpack;
    props.setCodeFiles(files);
    props.setActiveFiles(activeFile)
    return <pre></pre>;
}

interface SandpackComponentProps {
    template?: string | undefined,
    activeFile?: any,
    intitalFileState?: any,
    codeFiles?: any,
    handleUpdation?: any,
    setActiveFile?: any,
    typingAccessAllowed?: boolean,
    status?: string
}

const SandpackComponent: React.FC<SandpackComponentProps> = ({
    template,
    activeFile,
    intitalFileState,
    codeFiles,
    handleUpdation,
    setActiveFile,
    typingAccessAllowed,
    status
}) => {
    return (
      <Box sx={sandpackStyles}>
        <SandpackProvider template={template as any} options={{ activeFile: activeFile }} theme={nightOwl} files={intitalFileState} >
            <SandpackStack>
                <SandpackLayout autoCorrect='true' spellCheck={true} >
                    <SandpackFileExplorerWrapper  />
                    <CodeViewer codeFiles={codeFiles} setCodeFiles={handleUpdation} setActiveFiles={setActiveFile} />
                    <SandpackCodeEditorWrapper style={{ height: '630px' }} showTabs showLineNumbers={true} showInlineErrors wrapContent closableTabs key={codeFiles}/>
                    <SandpackPreviewWrapper style={{ height: '630px', width: '2000px' }} showOpenInCodeSandbox={false} />
                </SandpackLayout>
            </SandpackStack>
        </SandpackProvider>
      </Box>
    )
}

export default SandpackComponent;

const sandpackStyles:SxProps = {
  height:'700px',
  '@media(max-width:768px)':{
    background:'blue'
  }
}

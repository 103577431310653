import { Loader } from 'esbuild-wasm';

const C = '/images/C.svg';
const cplus = '/images/CPlus.svg';
const cSharp = '/images/csharp.svg';
const ruby = '/images/Ruby.svg';
const swift = '/images/swiftIcon.svg';
const typescript = '/images/TS.svg';
const react = '/images/React.svg';
const angular = '/images/Angular.svg';
const kotlin = '/images/kotlin.svg';
const flutter = '/images/flutter.svg';
const php = '/images/php.svg';
const python = '/images/python.svg';
const html = '/images/html.svg';
const css = '/images/css.svg';
const vue = '/images/vuejs.svg';
const java = '/images/java.svg';
const elixir = '/images/elixir.svg';
const go = '/images/go.svg';
const js = '/images/js.svg';
const dj = '/images/django.svg';
const express = '/images/express.svg';
const jupyter = '/images/jupyter.svg';
const spring = '/images/spring.svg';
const rails = '/images/rails.svg';
const mongo = '/images/mongodb.svg';
const mysql = '/images/mysql.svg';
const mongodb = '/images/mongodbLogo.svg';
const mssql = '/images/mssql.svg';
const bash = '/images/bashIcon.svg';
const step1Arrow = '/images/step1Arrow.svg';
const step2Arrow = '/images/step2Arrow.svg';
const step3Arrow = '/images/step3Arrow.svg';
const step4Arrow = '/images/step4Arrow.svg';
const step5Arrow = '/images/step5Arrow.svg';
const step6Arrow = '/images/step6Arrow.svg';
const rust = '/images/rust.svg';
const RustLogo = '/images/rustLogo.svg';
const RLogo = '/images/RLogo.svg';
const RubyLogo = '/images/RubyLogo.svg';
const PerlLogo = '/images/PerlLogo.svg';
const ElixirLogo = '/images/ElixirLogo.svg';
const cIcon = '/images/cIcon.svg';
const MongodbLogo = '/images/mongodbLogo.svg';
const DartLogo = '/images/DartLogo.svg';

export const baseUrl = process.env.REACT_APP_API_ENDPOINT;

export const linkExpiryUrl = '/';

export const TotalTime = 60;

export enum StackblitzDefaults {
  HEIGHT = '530',
  ID = 'stackblitz',
  OPEN_FILE = 'index.html',
}

export enum Time {
  MILLI_SECOND = 1,
  SECOND = 1000,
  MINUTE = 60 * SECOND,
  HOUR = 60 * MINUTE,
  DAY = 24 * HOUR,
}

export const TourSteps = {
  step1: {
    mainText: 'Select IDE ',
    subText:
      ' You can select a specific coding language from the list \n and start programming',
    arrow: step1Arrow,
  },
  step2: {
    mainText: 'Your code can be written here',
    subText: 'Select IDE and you can start coding here in this area',
    arrow: step2Arrow,
  },
  step3: {
    mainText: 'Reset Code',
    subText: 'Completely reset your code here to start over',
    arrow: step3Arrow,
  },
  step4: {
    mainText: 'Run Code  ',
    subText: 'Run code to view the output',
    arrow: step4Arrow,
  },
  step5: {
    mainText: 'Code Result',
    subText: 'Output of the code can be seen here',
    arrow: step5Arrow,
  },
  step6: {
    mainText: 'Request Demo ',
    subText: 'Request demo and contact our sales department',
    arrow: step6Arrow,
  },
};

export const GeneralIde = [
  {
    displayName: 'PHP',
    svg: php,
    IDE: 5,
    language: 'php',
  },
  // {
  //   displayName: 'Python2',
  //   svg: python,
  //   IDE: 6,
  //   language: 'python2',
  // },
  {
    displayName: 'C',
    svg: cIcon,
    IDE: 7,
    language: 'c',
  },
  {
    displayName: 'C++',
    svg: cplus,
    IDE: 8,
    language: 'cpp',
  },
  {
    displayName: 'C#',
    svg: cSharp,
    IDE: 9,
    language: 'csharp',
  },
  {
    displayName: 'Java',
    svg: java,
    IDE: 10,
    language: 'java',
  },
  {
    displayName: 'Go',
    svg: go,
    IDE: 11,
    language: 'go',
  },
  {
    displayName: 'Bash',
    svg: bash,
    IDE: 12,
    language: 'bash'
  },
  {
    displayName: 'Swift',
    svg: swift,
    IDE: 14,
    language: 'swift'
  },
  {
    displayName: 'Kotlin',
    svg: kotlin,
    IDE: 15,
    language: 'kotlin'
  },
  {
    displayName: 'Python',
    svg: python,
    IDE: 16,
    language: 'python3'
  },
  {
    displayName: 'Rust',
    svg: RustLogo,
    IDE: 19,
    language: 'rust'
  },
  {
    displayName: 'R',
    svg: RLogo,
    IDE: 20,
    language: 'r'
  },
  {
    displayName: 'Ruby',
    svg: RubyLogo,
    IDE: 21,
    language: 'ruby'
  },
  {
    displayName: 'Perl',
    svg: PerlLogo,
    IDE: 22,
    language: 'perl'
  },
  {
    displayName: 'Elixir',
    svg: ElixirLogo,
    IDE: 23,
    language: 'elixir'
  },
  {
    displayName: 'Dart',
    svg: DartLogo,
    IDE: 24,
    language: 'dart'
  },
];

export const FrontendOptions = [
  {
    language: 'vanilla',
    svg: js,
    IDE: 2,
    displayName: 'JavaScript',
    value: 'vanilla'
  },
  {
    language: 'typescript',
    svg: typescript,
    IDE: 4,
    displayName: 'TypeScript',
    value: 'react-ts'
  },
  {
    displayName: 'Angular',
    svg: angular,
    IDE: 3,
    language: 'angular',
    value:'angular'
  },
  // {
  //   language: 'Flutter',
  //   svg: flutter,
  //   IDE: 15
  // },
  {
    language: 'react',
    svg: react,
    IDE: 1,
    displayName: 'React',
    value:'react',
  },
  {
    language: 'html/css',
    svg: html,
    IDE: 17,
    displayName: 'HTML/CSS',
    value:'static'
  },
  // {
  //   language: 'CSS',
  //   svg: css,
  //   IDE: 18
  // },
  // {
  //   language: 'vue',
  //   svg: vue,
  //   IDE: 19,
  //   displayName: 'Vue JS'
  // }
];

export const frameworks = [
  // {
  //   language: 'django',
  //   svg: dj,
  //   IDE: 20,
  //   displayName: 'Django'
  // },
  // {
  //   language: 'express',
  //   svg: express,
  //   IDE: 21,
  //   displayName: 'Express'
  // },
  // {
  //   displayName: 'Jupyter',
  //   svg: jupyter,
  //   IDE: 18,
  //   language: 'jupyter'
  // },
  // {
  //   language: 'spring',
  //   svg: spring,
  //   IDE: 23,
  //   displayName: 'Spring'
  // },
  // {
  //   language: 'ruby',
  //   svg: rails,
  //   IDE: 24,
  //   displayName: 'Ruby on Rails'
  // },
  // {
  //   language: 'mongo db',
  //   svg: mongo,
  //   IDE: 25,
  //   displayName: 'MongoDB'
  // },
  // {
  //   language: 'mssql',
  //   svg: mssql,
  //   IDE: 26,
  //   displayName: 'MSSQL'
  // },
  {
    language: 'sql',
    svg: mysql,
    IDE: 13,
    displayName: 'SQL'
  },

  {
    language: 'mongodb',
    svg: mongodb,
    IDE: 25,
    displayName: 'MongoDB'
  }
];

export const FeNames = [
  'react',
  'angular',
  'vue',
  'javascript',
  'typescript',
  'html/css',
  'CSS',
];

export const LoaderValues = (language: string | undefined) => {
  let loader: Loader = 'js';
  switch (language?.toLowerCase()) {
    case 'typescript':
      loader = 'ts';
      break;
    case 'react':
      loader = 'jsx';
      break;
    case 'css':
      loader = 'css';
      break;
    default:
      break;
  }
  return loader;
};

export enum language {
  Javascript = 'javascript',
  Typescript = 'typescript',
  html = 'html/css',
  sql = 'sql',
  jupyter = 'jupyter'
}

export const SessionStorageKeys = {
  tourStatus: 'isTourOpen',
};

export enum constants {
  code = 'Type the code here'
}

export const datamodelsUrl = [
  {
    url: 'https://zecode-product.s3.ap-south-1.amazonaws.com/databaseModels/databaseModel1.png',
  },
];

export const mongoDBDatamodelsUrl = [
  {
    url: 'https://zecode-product.s3.ap-south-1.amazonaws.com/databaseModels/mongodbModel.png',
  },
];
